import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors, mobileThresholdPixels } from '../v3/styledComponents';

const Wrapper = styled.section`
  max-width: 1440px;
  position: relative;
  margin: 0 auto 120px auto;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
    padding: 0 15px;
  }
`;

const WrapperTop = styled.header`
  margin-bottom: 80px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const TopTitle = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.16em;
  color: ${colors.navy};
  margin: 0;
  text-transform: uppercase;
  position: relative;
  display: inline;

  &::before {
    content: '';
    width: 55px;
    border: 1px solid black;
    position: absolute;
    top: 36px;
    left: -80px;
  }

  &::after {
    content: '';
    width: 55px;
    border: 1px solid black;
    position: absolute;
    top: 36px;
    right: -80px;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 23px;
    line-height: 29px;

    &::before {
      width: 40px;
      top: 26px;
      left: -55px;
    }

    &::after {
      width: 40px;
      top: 26px;
      right: -55px;
    }
  }
`;

const TopSubtitle = styled.h3`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${colors.darkGrey2};
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.16em;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 15px;
  }
`;

const WrapperBody = styled.section`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Img = styled.img`
  display: block;
  width: 612px;
  max-width: 100%;
`;

const WrapperText = styled.article`
  padding: 45px;
  max-width: 625px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 30px 0 0 0;
  }
`;

const Info = styled.p`
  font-size: 10px;
  letter-spacing: 8px;
  color: ${colors.darkGrey2};
  margin: 0 0 10px 0;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 8px;
    letter-spacing: 6px;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 7px;
  color: ${colors.navy};
  margin: 0 0 20px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 5px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.black};
  margin: 0 0 60px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }
`;

const WrapperStyledLink = styled.footer`
  text-align: center;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${colors.navy};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 2px;
  }
`;

const Advice = ({ data: { topTitle, topSubtitle, img, alt, date, title, text, link, url } }) => (
  <Wrapper>
    <WrapperTop>
      <TopTitle>{topTitle}</TopTitle>
      <TopSubtitle>{topSubtitle}</TopSubtitle>
    </WrapperTop>
    <WrapperBody>
      <Img src={img} alt={alt} />
      <WrapperText>
        <Info>{date}</Info>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <WrapperStyledLink>
          <StyledLink to={url}>{link}</StyledLink>
        </WrapperStyledLink>
      </WrapperText>
    </WrapperBody>
  </Wrapper>
);

Advice.propTypes = {
  data: PropTypes.shape({
    topTitle: PropTypes.string,
    alt: PropTypes.string,
    img: PropTypes.string,
    topSubtitle: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default Advice;
