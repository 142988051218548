import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Wrapper = styled.section`
  max-width: 1440px;
  position: relative;
  margin: 0px auto 60px auto;
  padding: 0px 80px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 15px;
  }
`;

const Img = styled.img`
  width: 100%;
  display: block;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 10px;
  }
`;

const WrapperText = styled.article`
  max-width: 468px;
  position: absolute;
  top: 200px;
  right: 15%;

  @media (max-width: ${mobileThresholdPixels}) {
    position: relative;
    top: 0;
    left: 0;
  }
`;

const Info = styled.p`
  font-size: 12px;
  letter-spacing: 9px;
  color: ${colors.darkGrey2};
  margin: 0px 0px 10px 0px;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 8px;
    letter-spacing: 6px;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 10px;
  color: ${colors.navy};
  margin: 0px 0px 20px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    letter-spacing: 5px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.black};
  margin: 0px 0px 50px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }
`;

const StyledLink = styled(Link)`
  border: 1.5px solid ${colors.navy};
  box-sizing: border-box;
  width: 183px;
  height: 54px;
  display: flex;
  color: ${colors.navy};
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin-left: 85px;

  &:hover {
    background-color: ${colors.navy};
    color: ${colors.white};
  }

  @media (max-width: ${mobileThresholdPixels}) {
    margin: auto;
    width: 126px;
    height: 44px;
  }
`;

const LastCollab = ({ imgSrc }) => (
  <Wrapper>
    <Img src={imgSrc} alt="Dernière collab" />
    <WrapperText>
      <Info>Dernière collab</Info>
      <Title>Swarovski</Title>
      <Text>
        Tilli et Swarovski s’associent dans une démarche 100% upcycling et redonnent vie à des perles
        et pierres en cristal issues d’anciennes collections.
      </Text>
      <StyledLink to={'/la-rubrique/brillez-avec-swarovski'}>Découvrir</StyledLink>
    </WrapperText>
  </Wrapper>
);

LastCollab.propTypes = {
  imgSrc: PropTypes.string.isRequired,
};

export default LastCollab;
