import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';
import { Container, Content as StyledContent } from '../../components/home/homeStyledComponents';

import { eventsData } from '../../components/home/SustainableFashion/data';

import TopBar from '../../components/home/SustainableFashion/TopBar';
import LastCollab from '../../components/home/SustainableFashion/LastCollab';
import Advice from '../../components/home/SustainableFashion/Advice';
import LookBookBanner from '../../components/home/SustainableFashion/LookBookBanner';
import Events from '../../components/home/SustainableFashion/Events';
import Advices from '../../components/home/SustainableFashion/Advices';
import InstaFeed from '../../components/home/InstaFeed';
import Newsletter from '../../components/home/SustainableFashion/Newsletter';

import lastCollab from '../../assets/sustainableFashionPage/last-collab-beryl.png';
import advice from '../../assets/sustainableFashionPage/advice-of-the-moment.jpg';
import advices from '../../assets/sustainableFashionPage/advices.jpg';

const postDate = moment('2020-02-21');
const daysDiff = moment().diff(postDate, 'days');
const monthsDiff = moment().diff(postDate, 'months');
const dateSincePost = (monthsDiff >= 1) ? `${monthsDiff} mois` : `${daysDiff} jours`;

const Content = styled(StyledContent)`
  z-index: 1;
  ${({ extraTopPadding }) => extraTopPadding ? 'margin-top: -300px; padding-top: 300px;' : ''}
`;

const topBarData = {
  title: 'Mode durable',
  text: `Expression féminine (latin : modus durablus) :
  prendre le temps de s’amuser et de laisser parler sa créativité
  pour donner une seconde vie à son dressing et son intérieur !`,
};

const adviceData = {
  topTitle: 'Le conseil',
  topSubtitle: 'Du moment',
  img: advice,
  alt: 'Le conseil du moment',
  date: `Posté il y a ${dateSincePost}`,
  title: 'Comment retoucher sa robe de mariée ?',
  text: `Le choix de sa robe de mariée est souvent source de stress car
  le résultat doit forcément être à la hauteur de l'événement. Mais pour
   être la plus belle le jour J, il y aura forcément des retouches à prévoir
   sur votre robe de mariée. Découvrez nos conseils pour des retouches parfaites.`,
  link: "Voir l'article",
  url: '/la-rubrique/comment-retoucher-sa-robe-de-mariee/',
};

const advicesData = {
  img: advices,
  alt: 'Nos conseils',
  title: 'Nos conseils',
  text: `Découvrez toutes nos astuces pour prendre soin de votre dressing et
  remettre au goût du jour vos pièces favorites.`,
  link: 'Tous nos conseils',
  url: '/la-rubrique',
};

const SustainableFashionPage = () => (
  <Layout routeSlug="SustainableFashionPage">
    <Container>
      <Content extraTopPadding>
        <MenuBar />
        <TopBar data={topBarData} />
        <LastCollab imgSrc={lastCollab} />
        <Advice data={adviceData} />
      </Content>
      <LookBookBanner />
      <Content>
        <Events data={eventsData} />
        <Advices data={advicesData} />
        <InstaFeed />
        <Newsletter />
        <Footer />
      </Content>
    </Container>
  </Layout>
);

export default SustainableFashionPage;
