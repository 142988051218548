import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors, mobileThresholdPixels } from '../v3/styledComponents';

const Wrapper = styled.section`
  max-width: 1440px;
  margin: 0px auto 120px auto;
  padding: 120px 80px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 100px;
    padding: 80px 15px;
  }
`;

const WrapperTop = styled.header`
  margin-bottom: 80px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const TopTitle = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.16em;
  color: ${colors.navy};
  margin: 0px;
  text-transform: uppercase;
  position: relative;
  display: inline;

  &::before {
    content: '';
    width: 55px;
    border: 1px solid ${colors.black};
    position: absolute;
    top: 18px;
    left: -80px;
  }

  &::after {
    content: '';
    width: 55px;
    border: 1px solid ${colors.black};
    position: absolute;
    top: 18px;
    right: -80px;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 0.16em;

    &::before {
      width: 40px;
      top: 12px;
      left: -55px;
    }

    &::after {
      width: 40px;
      top: 12px;
      right: -55px;
    }
  }
`;

const WrapperBody = styled.section`
  display: flex;
  justify-content: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column-reverse;
  }
`;

const LeftColumn = styled.div`
  flex: 0px 0px 50%;
  padding-right: 49px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    padding-right: 0px;
  }
`;

const RightColumn = styled.div`
  flex: 0px 0px 50%;
  box-sizing: border-box;
`;

const Article = styled.article`
  display: flex;
  height: 245px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.lightGrey6};
  position: relative;
  padding-top: 40px;
  box-sizing: border-box;

  &:first-child {
    padding-top: 0px;
  }

  &:last-child {
    border-bottom: none;
    height: auto;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    height: auto;
    padding-top: 30px;

    &:first-child {
      padding-top: 30px;
    }
  }
`;

const ArticleDay = styled.div`
  flex: 0 0 65px;
  font-family: Libre Baskerville;
  font-size: 48px;
  line-height: 1;
  color: ${colors.navy};
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 35px;
    line-height: 43px;
    flex: 0 0 40px;
    margin-bottom: 10px;
  }
`;

const ArticleDate = styled.div`
  margin-right: 28px;
  flex: 0 0 auto;

  @media (max-width: ${mobileThresholdPixels}) {
    position: absolute;
    left: 45px;
    top: 32px;
  }
`;

const ArticleYear = styled.p`
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: ${colors.navy};
  margin: 0px;
`;

const ArticleHours = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: ${colors.darkGrey2};
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 12px;
  }
`;

const ArticleTitle = styled.h2`
  font-family: Libre Baskerville;
  font-size: 26px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: ${colors.navy};
  margin: 0px 0px 10px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const ArticleText = styled.p`
  color: ${colors.black};
  margin: 0px 0px 25px 0px;
  font-size: 18px;
  line-height: 26px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const FeaturedArticle = styled.article`
  padding-left: 49px;
  box-sizing: border-box;
  border-left: 1px solid ${colors.lightGrey6};

  @media (max-width: ${mobileThresholdPixels}) {
    border-bottom: 1px solid ${colors.lightGrey6};
    padding-left: 0px;
    border-left: none;
  }
`;

const FeaturedArticleDate = styled.p`
  font-family: Libre Baskerville;
  font-size: 48px;
  line-height: 60px;
  color: ${colors.navy};
  margin: 0px 0px 10px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 27px;
    line-height: 33px;
  }
`;

const FeaturedArticleHours = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: ${colors.darkGrey2};
  margin: 0px 0px 23px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 16px;
    margin: 0px 0px 10px 0px;
  }
`;

const FeaturedArticleTitle = styled.h2`
  font-family: Libre Baskerville;
  font-size: 26px;
  line-height: 26px;
  color: ${colors.navy};
  margin: 0px 0px 10px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin: 0px;
  }
`;

const FeaturedArticleText = styled.p`
  color: ${colors.black};
  margin: 0px 0px 30px 0px;
  font-size: 18px;
  line-height: 26px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const FeaturedArticleImg = styled.img`
  width: 584px;
  max-width: 100%;
  display: block;
  margin-bottom: 30px;
`;

const WrapperFooter = styled.footer`
  position: relative;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 30px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${colors.navy};
  position: absolute;
  left: 75%;
  transform: translateX(-25%);

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    left: 50%;
    transform: translateX(-50%);
    text-decoration: underline;
    font-size: 12px;
  }
`;

const Events = ({ data: { articles, featuredArticle, footerLink } }) => (
  <Wrapper>
    <WrapperTop>
      <TopTitle>Les events</TopTitle>
    </WrapperTop>
    <WrapperBody>
      <LeftColumn>
        {articles.map(article => (
          <Article key={article.title}>
            <ArticleDay>{article.day}</ArticleDay>
            <ArticleDate>
              <ArticleYear>{article.date}</ArticleYear>
              <ArticleHours>{article.hours}</ArticleHours>
            </ArticleDate>
            <div>
              <ArticleTitle>{article.title}</ArticleTitle>
              <ArticleText>{article.text}</ArticleText>
            </div>
          </Article>
        ))}
      </LeftColumn>
      <RightColumn>
        <FeaturedArticle>
          <FeaturedArticleDate>{featuredArticle.date}</FeaturedArticleDate>
          <FeaturedArticleHours>{featuredArticle.hours}</FeaturedArticleHours>
          <FeaturedArticleTitle>{featuredArticle.title}</FeaturedArticleTitle>
          <FeaturedArticleText>{featuredArticle.text}</FeaturedArticleText>
          <FeaturedArticleImg src={featuredArticle.img} alt={featuredArticle.alt} />
        </FeaturedArticle>
      </RightColumn>
    </WrapperBody>
    <WrapperFooter>
      <StyledLink to={footerLink.url}>{footerLink.link}</StyledLink>
    </WrapperFooter>
  </Wrapper>
);

Events.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.shape({
      day: PropTypes.string,
      date: PropTypes.string,
      hours: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    })),
    featuredArticle: PropTypes.shape({
      date: PropTypes.string,
      hours: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      img: PropTypes.string,
      alt: PropTypes.string,
    }),
    footerLink: PropTypes.shape({
      link: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
};

export default Events;
