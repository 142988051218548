import eventsFeaturedArticle from '../../../assets/sustainableFashionPage/events-featured-article.jpg';

export const eventsData = {
  articles: [
    {
      day: '21',
      date: 'Déc 2019',
      hours: 'De 16h à 18h',
      title: 'Aubade',
      text: `Une Tilliste sera présente dans la boutique Aubade située
      rue Tronchet dans le 8ème arrondissement de Paris
      pour personnaliser votre lingerie : ajout de strass, d’un joli nœud ou d’une initiale.
      Une belle idée cadeau !`,
    },
    {
      day: '14',
      date: 'Déc 2019',
      hours: 'De 16h à 18h',
      title: 'The Socialite Family',
      text: `Nos Tillistes seront dans la boutique The Socialite Family
      rue Saint-Fiacre pour broder à la main votre linge de maison.`,
    },
    {
      day: '14',
      date: 'Déc 2019',
      hours: 'De 16h à 18h',
      title: 'Le BHV Marais',
      text: 'Tilli dévoile son corner “Création d’accessoires upcyclés” à L’Atelier des Experts du BHV Marais.',
    },
  ],
  featuredArticle: {
    date: '17 Janvier 2020',
    hours: 'De 10h à 18h',
    title: 'Salon IMPACT',
    text: `Retrouvez-nous au Salon IMPACT organisé par WHO'S NEXT du 17 au 20 janvier 2020 !
    Hall 6 Stand A15, Paris Expo Porte de Versailles.`,
    img: eventsFeaturedArticle,
    alt: 'Tous nos events',
  },
  footerLink: {
    link: 'Tous nos events',
    url: '/nos-evenements',
  },
};
