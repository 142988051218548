import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import lookBook from '../../../assets/sustainableFashionPage/look-book.jpg';
import lookBookMobile from '../../../assets/sustainableFashionPage/look-book-mobile.jpg';
import withAppContext from '../../../withAppContext';

import { colors, mobileThresholdPixels, mobileMenuBarHeightPixels } from '../v3/styledComponents';

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.section`
  position: relative;
  margin-bottom: 0px;
  height: 364px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 261px;
  }
`;

const Background = styled.img`
  position: fixed;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: ${mobileMenuBarHeightPixels};
`;

const WrapperText = styled.article`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${colors.white};
  text-align: center;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 20px;
  }
`;

const Title = styled.h2`
font-family: Libre Baskerville;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 57px;
letter-spacing: 8px;
transform: rotate(-0.04deg);
text-transform: uppercase;
margin: 0px 0px 10px 0px;
margin-block-start: 0px;
margin-block-end: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 25px;
    letter-spacing: 5px;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin: 0px 0px 50px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 20px 0px;
  }
`;

const StyledLink = styled(Link)`
  border: 1.5px solid ${colors.white};
  box-sizing: border-box;
  width: 183px;
  height: 54px;
  color: ${colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 126px;
    height: 44px;
  }
`;

const LookBook = ({ context: { isMobile } }) => (
  <Container>
    <Background src={isMobile ? lookBookMobile : lookBook} />
    <Wrapper>
      <WrapperText>
        <Title>Lookbook v1</Title>
        <Text>Faire du shopping dans son propre dressing. Le plein d’inspiration à découvrir ici.</Text>
        <StyledLink to={'/lookbook-hiver-v1-2019-2020'}>Découvrir</StyledLink>
      </WrapperText>
    </Wrapper>
  </Container>
);

LookBook.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withAppContext(LookBook);
