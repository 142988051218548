import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

const Wrapper = styled.section`
  text-align: center;
  margin: 80px auto 90px auto;
  padding: 0px 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
    margin-top: 60px;
    padding: 0px 15px;
  }
`;

const Title = styled.h1`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.16em;
  color: ${colors.navy};
  margin: 0px 0px 60px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`;

const InstagramFeed = () => (
  <>
    <Helmet>
      <script src="https://cdn.lightwidget.com/widgets/lightwidget.js" />
    </Helmet>
    <Wrapper>
      <Title>Suivez-nous sur instagram @tilli.couture</Title>
      <iframe
        src="//lightwidget.com/widgets/f7913e637a8d565995ec55159a67704b.html"
        scrolling="no"
        // eslint-disable-next-line react/no-unknown-property
        allowtransparency="true"
        className="lightwidget-widget"
        style={{
          width: '100%',
          border: '0',
          overflow: 'hidden',
        }}
      />
    </Wrapper>
  </>
);

export default InstagramFeed;
